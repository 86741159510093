<template>
	<div class="create-place">
		<div class="wrapper">
			<section class="placing-ad-section">
				<div class="container">
					<Sidebar :steps="steps" @changeStep="changeStepFromSideBar" :menu="menuList" />

					<div class="placing">
						<!-- <router-link to="/mobile-ad-menu" class="backbutton">
							<p>Назад</p>
						</router-link> -->

						<div class="placing__title-wrapper">
							<div class="placing__title-wrapper__steps placing-steps">
								<ul class="placing-steps__list">
									<li class="placing-steps__item" v-for="i in length" :key="i">
										<p :class="[
											'placing-steps__item-num',
											step >= i && 'passed'
										]">
											{{ i }}
										</p>
									</li>
								</ul>
							</div>

							<div class="placing__title-wrapper__text">
								<h2 class="section-title">{{ current.title }}</h2>
							</div>
						</div>

						<component :is="current.component" :place="place" />
					</div>
					<div class="step-pagination">
						<div class="step-pagination__container">
							<a href="" @click.prevent="changeStep(true)"
								class="step-pagination__button step-pagination__button_prev">
								Назад
							</a>

							<button @click="changeStep()" type="button" :disabled="isLastSave"
								class="step-pagination__button step-pagination__button_next">
								{{ length > step ? 'Далее' : 'Сохранить' }}
							</button>

							<button @click="submit" type="button" :disabled="isDisabledSubmit"
								class="btn step-pagination__button step-pagination__button_next">
								Завершить редактирование
							</button>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/views/createPlace/Sidebar'

import types from '@/components/views/createPlace/steps/types'
import booking from '@/components/views/createPlace/steps/booking'
import front from '@/components/views/createPlace/steps/front'
import guests from '@/components/views/createPlace/steps/guests'
import location from '@/components/views/createPlace/steps/location'
import price from '@/components/views/createPlace/steps/price'
import rules from '@/components/views/createPlace/steps/rules'
import amentities from '@/components/views/createPlace/steps/amentities'

import { SET, CLEAR } from '@/constants/store/place/mutations'
import modals from '@/constants/modals'

// import isEqual from '@/utils/isEqual'

const _config = [
	{
		title: 'Какое жилье вы сдаете?',
		component: 'types'
	},
	{
		title: 'Удобства жилья',
		component: 'amentities'
	},
	{
		title: 'Сколько гостей вы можете принять?',
		component: 'guests'
	},
	{
		title: 'Название, описание жилья и фотографии',
		component: 'front'
	},
	{
		title: 'Расскажите о местоположении вашего жилья',
		component: 'location'
	},
	{
		title: 'Расскажите о правилаx дома для гостей',
		component: 'rules'
	},
	{
		title: 'Условия бронирования',
		component: 'booking'
	},
	{
		title: 'Стоимость',
		component: 'price'
	}
]

export default {
	name: 'CreatePlace',
	components: {
		Sidebar,

		types,
		booking,
		front,
		guests,
		location,
		price,
		rules,
		amentities
	},
	watch: {
		step: async function (val) {
			this.current = this.config[val - 1]
		},
		flagsChangeAndSaveData: {
			handler() {
				localStorage.setItem(
					'flagsChangeAndSaveData',
					JSON.stringify(this.flagsChangeAndSaveData)
				)
			},
			deep: true
		}
	},
	data() {
		return {
			current: {},
			steps: {},
			isLastSave: false,
			menuList: [
				{
					title: 'Тип жилья',
					link: 'types',
					key: 'step_type',
					isRequired: true,
					isMissed: false
				},
				{
					title: 'Удобства жилья',
					link: 'amentities',
					key: 'step_amenities',
					isRequired: false,
					isMissed: false
				},
				{
					title: 'Количество гостей',
					link: 'guests',
					key: 'step_guests',
					isRequired: true,
					isMissed: false
				},
				{
					title: 'Название, описание фотографии',
					link: 'front',
					key: 'step_description',
					isRequired: true,
					isMissed: false
				},
				{
					title: 'Расположение',
					link: 'location',
					key: 'step_location',
					isRequired: true,
					isMissed: false
				},
				{
					title: 'Правила дома',
					link: 'rules',
					key: 'step_rules',
					isRequired: false,
					isMissed: false
				},
				{
					title: 'Условия бронирования',
					link: 'booking',
					key: 'step_conditions',
					isRequired: true,
					isMissed: false
				},
				{
					title: 'Стоимость проживания',
					link: 'price',
					isRequired: true,
					key: 'step_price',
					isMissed: false
				}
			],
			form: {},
			statuses: {},
			countUpdateResultObject: 0, // the first data change comes from the server
			flagsChangeAndSaveData: {
				_isClickNextButton: false // submit step
			}
		}
	},
	computed: {
		isDisabledSubmit() {
			// handle edit variant


			if (this.verificationStatus !== null) {
				if (Object.values(this.flagsChangeAndSaveData).includes(false)) {
					return true
				}

				return false
			}

			if (!this.steps || !Object.keys(this.steps).length) {
				return true
			} else {
				let isAllFieldsAreFilled = true
				const _fieldIsNotRequiered = 'step_rules'

				Object.entries(this.steps).forEach(([key, value]) => {
					if (key === _fieldIsNotRequiered) {
						return
					}

					if (!value) {
						isAllFieldsAreFilled = false
					}
				})

				return !isAllFieldsAreFilled
			}
		},
		isNextButtonNotDisabled() {
			return this.step <= this.length
		},
		config() {
			return _config
		},
		verificationStatus() {
			return this.$store.state.place.verification_status
		},
		step() {
			return this.$store.state.place.current
		},
		place() {
			return this.$store.state.place.place_id
		},
		length() {
			return this.menuList.length
		},
		statusesWhenAdShouldSendOnModeration() {
			return [1, 2, 3, 4, 5]
		}
	},
	beforeRouteLeave: function (to, from, next) {
		this.clear()

		next()
	},
	methods: {
		showSuccessCreatePlace(flag) {
			// flag = true -> success change place
			const text = flag
				? 'Объявление успешно сохранено и отправлено на модерацию!'
				: 'Объявление успешно сохранено!'

			this.$store.commit('showModal', {
				name: modals.SUCCESS_CREATE_OR_CHANGE_PLACE,
				props: { text },
				isShow: true
			})
		},
		async getStatuses() {
			const statuses = await this.$api.place.getStatuses({
				place_id: this.place
			})

			if (statuses.status) {
				this.steps = statuses.response
			}
		},
		clear() {
			this.$store.commit(CLEAR)
			localStorage.removeItem('flagsChangeAndSaveData')
		},
		async submit() {
			await this.getStatuses()

			if (this.isDisabledSubmit) {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Заполнены не все поля'
				})

				return
			}

			setTimeout(async () => {
				if (
					this.statusesWhenAdShouldSendOnModeration.includes(
						this.verificationStatus
					)
				) {
					await this.$api.place.sendToVerification({ place_id: this.place })

					this.showSuccessCreatePlace(true)
				} else {
					this.showSuccessCreatePlace()
				}

				this.clear()

				this.$router.push({ path: '/profile/my-housing' })
			}, 300)
		},
		async changeStep(toggle) {
			if (toggle) {
				// prev
				if (this.step > 1) {
					this.$store.commit(SET, { key: 'current', value: this.step - 1 })
				}

				// if (!this.flagsChangeAndSaveData._isChangeData) {
				// 	this.countUpdateResultObject = 0
				// }
			} else {
				this.$bus.trigger('next-step', async (place_id) => {

					if (!place_id) {
						this.menuList = this.menuList.map((itm, idx) =>
							idx + 1 === this.step
								? { ...itm, isPassed: false, isMissed: true }
								: itm
						)

						// temporary code

						if (this.step === 4) {
							this.$store.commit('showNotification', {
								type: 'error',
								text: 'Выбранного адреса не существует'
							})
						} else {
							this.$store.commit('showNotification', {
								type: 'error',
								text: 'Ошибка сохранения'
							})
						}

						// - temporary code
						return
					}

					// this.update()

					this.$store.commit(SET, { key: 'place_id', value: place_id })

					this.menuList = this.menuList.map((itm, idx) =>
						idx + 1 === this.step
							? { ...itm, isPassed: true, isMissed: false }
							: itm
					)

					await this.getStatuses()

					// if change data after click on save = false - count - 0
					// if (!this.flagsChangeAndSaveData._isChangeData) {
					// 	this.countUpdateResultObject = 0
					this.flagsChangeAndSaveData._isClickNextButton = true
					// } else {
					// flag _isClickNextButton true(for edit)
					// }

					if (this.step < this.length) {
						this.$store.commit(SET, { key: 'current', value: this.step + 1 })
					}
				})
			}
		},
		async del() {
			if (this.place) {
				const response = await this.$store.dispatch(DELETE, {
					place_id: this.place
				})

				if (response && !response.status) {
					this.$store.commit('showNotification', {
						type: 'error',
						text: 'Ошибка удаления'
					})

					return
				}

				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Удаление прошло успешно'
				})
			}
		},
		update(payload) {
			// this.form = { ...payload }

			// if (this.countUpdateResultObject < 1) {
			// 	this.preLoadModalForDisableButton = JSON.parse(JSON.stringify(payload))
			// }

			// flag _isChangeData true(for edit)
			// if (this.countUpdateResultObject >= 1) {
			// first time is init value from serve
			// if (!isEqual(payload, this.preLoadModalForDisableButton)) {
			// this.flagsChangeAndSaveData._isChangeData = true
			// } else {
			// this.flagsChangeAndSaveData._isChangeData = false
			// }
			// }

			this.countUpdateResultObject++
		},
		changeStepFromSideBar() {
			// data to be sent is reset when changing the step in the menu
			// this.flagsChangeAndSaveData._isClickNextButton = false
			// if (!this.flagsChangeAndSaveData._isClickNextButton) {
			// }

			this.countUpdateResultObject = 0
		}
	},
	created() {
		this.current = this.config[this.step - 1]
	},
	async beforeMount() {
		if (!this.place) {
			return
		}

		const response = await this.$api.place.getStatuses({
			place_id: this.place
		})

		// check place object is delete
		if (response.code === 404) {
			this.$store.commit(CLEAR)

			return
		}

		if (response.status) {
			this.steps = response.response
		}
	},
	async mounted() {
		// await this.get()

		if (localStorage.getItem('flagsChangeAndSaveData')) {
			this.flagsChangeAndSaveData = JSON.parse(
				localStorage.getItem('flagsChangeAndSaveData')
			)
		}
	}
}
</script>

<style lang="sass" scoped>
.step-pagination
	&__button
		width: auto
		min-width: 140px
		margin-right: 10px
	&__button:disabled
		background-color: #a9a9a9
</style>
