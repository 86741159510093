<template>
  <!-- НЕ ТРОГАТЬ tk-autocomplete -->
  <tk-autocomplete
    class="location location__input location__input_city"
    id="destination"
    v-model="inpt"
    :fetch-suggestions="querySearch"
    :debounce="1000"
    placeholder="Адрес"
    :trigger-on-focus="false"
    :teleported="false"
    clearable
    @select="select"
    ref="aut"
    v-if="values"
  />
  <form v-if="values" action="#" class="location" id="location" @submit.prevent>
    <label class="location__description_label" for="location__description">
      Опишите инфраструктуру местности. Расскажите подробнее о нахождении рядом
      с жильем автобусных остановок, станций метро, магазинов и т.д.
    </label>
    <textarea
      class="location__input location__input_description"
      name="location__description"
      id="location__description"
      placeholder="Описание расположения

Пример: Апартаменты расположены в нешумном районе, в 400 метрах находится торговый центр, внутри которого есть небольшой парк и фудкорты. Напротив ТЦ расположена автобусная остановка. Путь до моря составляет чуть больше 10 минут."
      v-model="values.address_description"
    />
  </form>
</template>

<script>
export default {
  props: {
    place: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      inpt: '',
      values: null,
      fias: [],
      selectDisabled: true,
    }
  },
  async created() {
    this.$bus.on('next-step', this.submit)

    const response = await this.$api.place.getLocation({
      place_id: this.place
    })

    if (response?.status) {
      this.values = { ...response.response }

      if (this.values?.dadata) {
        this.inpt = this.values.dadata.address
        this.values.fias_id = this.values.dadata.fias_id
      }
    }
  },
  unmounted() {
    this.$bus.off('next-step', this.submit)
    document.removeEventListener("pointerup", this.autocompleteWorkaround)
  },
  mounted() {
    document.addEventListener("pointerup", this.autocompleteWorkaround)
  },
  methods: {
    async submit(callback) {
      const response = await this.$api.place.setLocation({
        place_id: this.place,
        value: {
          ...this.values
        }
      })

      if (response?.status) {
        callback(response.response?.place_id)
      } else {
        callback()
      }
    },
    async querySearch(queryString, callback) {
      if (!queryString || queryString.length < 3) {
        return
      }

      const response = await this.$api.search.getAddress({ find: queryString })

      if (response && response.error) {
        return
      }

      this.selectDisabled = false
      this.fias = [...response.response]
      callback([...response.response])
    },
    select(event) {
      if (!this.selectDisabled) {
        this.values.fias_id = event.fias_id
        this.inpt = event.value
      }
    },
    autocompleteWorkaround(event) {
      if (event.target?.id?.search(/el-autocomplete-\d+-item-\d+/) !== -1) {
        if (!this.selectDisabled) {
          this.selectDisabled = true
          const idx = parseInt(event.target.id.replace(/el-autocomplete-\d+-item-/, ''))
          this.values.fias_id = this.fias[idx].fias_id
          this.inpt = this.fias[idx].value
        }
      }
    },
  }
}
</script>

<style lang="sass">
.location__input
  &_description
    padding: 10px 15px
    background: #FFFFFF
    border: 1px solid #E2E2E2
    border-radius: 12px
    resize: none
    min-height: 200px
  &_city
    .el-input
      &__wrapper
        height: 55px
        padding: 0 15px
        border-radius: 12px

        &.is-focus
          box-shadow: 0 0 0 1px $green inset !important
</style>

<style lang="scss">
.location__input_city {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-input {
    height: auto !important;
  }

  input {
    padding: 0;
    font-size: 16px;
  }
}

.el-autocomplete-suggestion{
  min-width: inherit !important;
  li{
    padding: 0px 20px 10px 20px;
    line-height: 1;
    white-space: normal;
  }

}
.el-autocomplete__popper{
  margin-right: 10px !important;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
</style>
