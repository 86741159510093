<template>
	<form v-if="values" action="#" class="photos" id="photos">
		<p class="photos__subtitle">
			Хорошие фото привлекают гостей. Так они получат предварительное
			представление о вашем жилье.
		</p>
		<ol class="photos-advices">
			<li class="photos-advices__item">
				Фотографируйте горизонтально. Так фото будет выглядеть объемнее и вы
				сможете показать как можно больше
			</li>
			<li class="photos-advices__item">
				Свет – одно из главных условий для хорошего фото. Используйте
				естественное освещение и не фотографируйте со вспышкой.
			</li>
			<li class="photos-advices__item">
				Покажите все доступные гостю комнаты: спальни, ванные комнаты, кухню и
				гостиную.
			</li>
			<li class="photos-advices__item">
				В описании жилья укажите: качество мебели, состояние ремонта, наличие
				парковки, шумоизоляция, описание района жилья.
			</li>
			<li class="photos-advices__item" v-if="!isPlaceHouseType">
				В описании комнат укажите: размер комнаты, качество мебели и спального
				места, состояние ремонта, данные по шумоизоляции, вид из окна или
				наличие балкона.
			</li>
		</ol>
		<div class="photos__inputs-w-uploader">
			<div class="photos__inputs">
				<tk-input type="text" v-model="values.title" class="front-room__input-title"
					:error="validatorPresence(values.title, values._wasTitleInteracted)" placeholder="Название вашего жилья"
					@focusout="values._wasTitleInteracted = true" />
				<tk-textarea class="photos__textarea textarea-component" name="place__descriptionion" v-model="values.description"
					id="place__description"
					placeholder="Описание вашего жилья

Пример: Новые двухуровневые апартаменты со всеми удобствами. Удобная гостиная с кухней и ванной комнатой на первом уровне и красивая спальня на втором уровне. Апартаменты построены в новом евростиле. Район тихий, нет шумных вечеринок." />
			</div>
			<Uploader class="photos__uploader" @update="(evt) => (values.images = [...evt])" :defaultImages="values.images" />
		</div>
		<!-- <el-button class="upload" type="primary"> Добавить фотографии </el-button> -->

		<!-- <Uploader
			@update="(evt) => (values.images = [...evt])"
			:defaultImages="values.images"
		/> -->
	</form>

	<Rooms class="photos__rooms" v-if="!isPlaceHouseType" :type-place="typePlace" :content="rooms"
		:propagate-validation="propagateValidation" @update="(evt) => (rooms = [...evt])" />
</template>

<script>
import placeTypes from '@/constants/placeTypes'
import sizes from '@/constants/sizes'
import Rooms from '../rooms.vue'
import Uploader from '../uploader.vue'

export default {
	components: { Rooms, Uploader },
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			rooms: [],
			typePlace: null,
			key: 1,
			values: null,
			loading: false,
			propagateValidation: false
		}
	},
	async beforeMount() {
		this.$bus.on('next-step', this.submit)

		// type place
		if (!this.isPlaceHouseType) {
			const types = await this.$api.place.getTypes({ place_id: this.place })

			if (types?.status) {
				this.typePlace = types.response.room_types.find((itm) => itm.value)?.id
			}
		}

		const response = await this.$api.place.getDescription({
			place_id: this.place
		})

		if (response?.status) {
			response.response.images = response.response.images.map((itm) => {
				return {
					id: itm.id,
					...itm.image
				}
			})
			// response.response.images = response.response.images.map((itm) => itm.image)
			this.values = { ...response.response }
		}

		// комнаты
		const rooms = await this.$api.place.getRooms({ place_id: this.place })

		if (rooms?.status) {
			rooms.response.rooms.forEach((room) => {
				room.images = room.images.map((itm) => {
					return {
						id: itm.id,
						...itm.image
					}
				})
				room.beds.forEach((bed) => {
					bed.images = bed.images.map((itm) => {
						return {
							id: itm.id,
							...itm.image
						}
					})
				})
			})
			this.rooms = [...rooms.response.rooms]
		}
	},
	unmounted() {
		this.$bus.off('next-step', this.submit)
	},
	computed: {
		isPlaceHouseType() {
			return placeTypes.HOUSE === this.typePlace
		},
		maxSize() {
			return sizes.megabyte * 10
		}
	},
	methods: {
		/**
		 * Поле должно быть заполнено и не пробелами
		 *
		 * @param title
		 * @param showError
		 * @param errorMsg
		 * @returns {boolean}
		 */
		validatePresence(title, showError = false, errorMsg = '') {
			let isValid = true

			if (!title?.trim()) {
				isValid = false
				if (showError) {
					this.$store.commit('showNotification', {
						type: 'error',
						text: errorMsg
					})
				}
			}

			return isValid
		},

		/**
		 * Хотя бы одна фотография должна быть прекреплена
		 *
		 * @param images
		 * @param showError
		 * @param errorMsg
		 * @returns {boolean}
		 */
		validateImages(images, showError = false, errorMsg = '') {
			let isValid = true

			if (!images[0]) {
				isValid = false
				if (showError) {
					this.$store.commit('showNotification', {
						type: 'error',
						text: errorMsg
					})
				}
			}

			return isValid
		},

		/**
		 * Проверяем данные и выводим ошибки, если данные не корректны
		 *
		 * @returns {boolean}
		 */
		validate() {
			this.markWasInteracted()
			let isValid = true

			isValid &= this.validatePresence(this.values.title, true, 'Укажите название вашего жилья')
			isValid &= this.validateImages(this.values.images, true, 'Добавьте фотографии жилья')

			if (!this.isPlaceHouseType) {
				if (!this.rooms[0]) {
					isValid = false
					this.$store.commit('showNotification', {
						type: 'error',
						text: 'Добавьте информацию о комнатах'
					})
				} else {
					this.rooms.forEach((room) => {
						isValid &= this.validatePresence(room.title, true, 'Укажите название для комнаты')
						isValid &= this.validatePresence(room.description, true, 'Коротко опишите каждую комнату')
						//isValid &= this.validateImages(room.images, 'Добавьте фотографии для каждой комнаты')
					})
				}
			}

			return isValid
		},

		/**
		 * Передаем ошибку в tk компонент с пояснением только если
		 * проверка провалена и ранее поле было не активно
		 *
		 * @param title
		 * @param show
		 * @returns {{}|{text: string, status: string}}
		 */
		validatorPresence(title, show) {
			if (this.validatePresence(title) || !show) {
				return {}
			} else {
				return { status: 'error', text: 'Заполните поле' }
			}
		},

		markWasInteracted() {
			this.propagateValidation = true
			this.values._wasTitleInteracted = true
		},

		async submit(callback) {
			// Проверяем данные перед отправкой
			if (!this.validate()) {
				return
			}
			const response = await this.$api.place.setDescription({
				place_id: this.place,
				value: {
					...this.values,
					//images: this.values.images.map((itm) => itm.hash ? ({ image: itm.hash }) : null).filter(itm => itm)
					images: this.values.images.map((itm) => {
						if (itm.id) {
							return {
								id: itm.id,
								image: itm.hash ?? itm.image
							}
						} else {
							return {
								image: itm.hash ?? itm.image
							}
						}
					})
				}
			})

			if (!this.isPlaceHouseType) {
				const payload = this.rooms.map((item) => ({
					...item,
					beds: item.beds.map((itm) => ({
						...itm,
						images: itm.images?.length
							? [itm.images[0].id ?
								{
									id: itm.images[0].id,
									image: itm.images[0].hash ?? itm.images[0].image
								} : {
									image: itm.images[0].hash ?? itm.images[0].image
								}


								// itm.images[0]?.id
								//   ? { image: itm.images[0].image.hash }
								//   : itm.images[0] && { image: itm.images[0]?.hash }
							]
							: []
					})),
					// images: item.images.map((itm, idx) =>
					//   itm.hash ? { is_main: idx === 0 ? 1 : 0, image: itm.hash } : itm
					// )
					images: item.images.map((itm) => {
						if (itm.id) {
							return {
								id: itm.id,
								image: itm.image ?? itm.hash
							}
						} else {
							return {
								image: itm.image ?? itm.hash
							}
						}
					})
				}))

				// поскольку id назначенное просто текущее время
				// не забываем убрать перед отправкой
				const timeNow = Date.now() - 60000000
				payload.forEach((room) => {
					if (room.id >= timeNow) delete room.id
				})

				const roomResponse = await this.$api.place.setRooms({
					place_id: this.place,
					value: {
						rooms: payload
					}
				})

				if (!roomResponse.status) {
					if (roomResponse.errors?.message === 'Не корректные данные изображения') {
						this.$store.commit('showNotification', {
							type: 'error',
							text: 'Пожалуйста, дождитесь загрузки фотографий жилья'
						})
					} else {
						this.$store.commit('showNotification', {
							type: 'error',
							text: 'Название, описание и фото обязательны для заполнения'
						})
					}

					return
				}
			} else {
				// const roomResponse = await this.$api.place.setRooms({
				//   place_id: this.place,
				//   value: {
				//     rooms: []
				//   }
				// })
				//
				// if (!roomResponse.status) {
				//   if (roomResponse.errors?.message === 'Не корректные данные изображения') {
				//     this.$store.commit('showNotification', {
				//       type: 'error',
				//       text: 'Пожалуйста, дождитесь загрузки фотографий жилья'
				//     })
				//   } else {
				//     this.$store.commit('showNotification', {
				//       type: 'error',
				//       text: 'Название, описание и фото обязательны для заполнения'
				//     })
				//   }
				//
				//   return
				// }
			}

			if (response?.status) {
				callback(response.response?.place_id)
			} else {
				callback()
				if (response.errors?.message === 'Не корректные данные изображения') {
					this.$store.commit('showNotification', {
						type: 'error',
						text: 'Пожалуйста, дождитесь загрузки фотографий жилья'
					})
				} else {
					this.$store.commit('showNotification', {
						type: 'error',
						text: 'Название, описание и фото обязательны для заполнения'
					})
				}
			}
		}
	}
}
</script>

<style lang="sass">
.photos
	display: grid
	grid-template-columns: 420px 320px
	grid-column-gap: 20px
	&__subtitle
		font-weight: 600
		font-size: 16px
		line-height: 18px
		grid-column: 1 / 2
	&-advices
		font-size: 16px
		line-height: 18px
		padding-left: 15px
		margin-bottom: 40px
		grid-column: 1 / 2
		&__item
			margin-bottom: 10px
	&__inputs-w-uploader
		grid-column: 1 / -1
		display: grid
		grid-template-columns: 420px 320px
		grid-column-gap: 20px
	&__uploader
		position: relative
		top: -31px
	&__textarea
		caret-color: #1AC386
		border: none
		margin-bottom: 30px
		margin-top: 25px
		&_description

	&__rooms
		flex-basis: 100%

@media screen and (max-width: 1180px)
	.photos
		grid-template-columns: 359px 320px

@media screen and (max-width: 1024px)
	.photos
		grid-template-columns: 285px 320px

@media screen and (max-width: 912px)
	.photos
		grid-template-columns: 1fr
@media screen and (max-width: 800px)
	.photos
		&__inputs-w-uploader
			grid-template-columns: 1fr
		&__uploader
			top: 0
			margin: 0 auto 30px 0

			h3
				text-align: left
		&__rooms
			margin: 0 auto 20px auto
</style>
