<template>
	<div class="sidebar">
		<!-- <router-link to="/mobile-menu" class="backbutton">
			<p>Назад</p>
		</router-link> -->
		<div class="profile-wrap">
			<div class="sidebar__title">
				<div class="title">Профиль</div>
				<router-link to="/profile" class="edit-icon"></router-link>
			</div>

			<router-link to="/profile" class="sidebar__info">
				<div class="info__userpic">
					<tk-image
						:src="$store.getters.avatar"
						width="60"
						height="60"
						:alt="$store.getters.fullName"
					/>
				</div>
				<div class="info__username">
					{{ $store.getters.fullName }}
				</div>
				<!-- <div class="info__progress">
					<div class="progress-medal">
						<img
							src="~@/assets/images/svg/medal-citizen.svg"
							height="17"
							width="17"
							alt="Медаль"
						/>
					</div>
					<div class="progress">Жилец</div>
				</div> -->
			</router-link>
		</div>

		<ul class="sidebar__menu">
			<li
				v-for="(item, index) in menu"
				:key="item.link"
				:class="`menu-item ${create.current - 1 === index && 'active'} ${
					item.isRequired && 'required'
				} ${item.isMissed ? 'missed' : ''} ${
					steps[item.key] || item.isPassed ? 'passed' : ''
				}`"
			>
				<a href="" @click.prevent="go(index)">
					<div class="text">
						{{ item.title }}
						<template v-if="item.isRequired">*</template>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { SET } from '@/constants/store/place/mutations'
import { customRef } from "vue";

export default {
	name: 'SidebarAd',
	props: {
		menu: {
			type: Array,
			default: () => []
		},
		steps: {
			type: Object,
			default: () => ({
				step_amenities: false,
				step_conditions: false,
				step_description: false,
				step_guests: false,
				step_location: false,
				step_price: false,
				step_rules: false,
				step_type: false,
				step_user_confirmation: false
			})
		}
	},
	computed: {
		create() {
			return this.$store.state.place
		}
	},
	methods: {
		go(index) {
      const currentStep = this.create.current - 1 // запоминаем на шаге были на начало перехода
      if (!this.steps.step_type || ((this.menu[currentStep].isMissed || !this.steps.step_type) && currentStep === 0)) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Укажите тип жилья и расположение гостей.'
        })
        return
      }

			this.$store.commit(SET, { key: 'current', value: index + 1 })
			this.$emit('changeStep')

      // Проверяем, и если шаг не заверешен - выводим сообщение
      if (!this.menu[currentStep].isMissed && !this.steps[this.menu[currentStep].key]) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Данные предыдущего шага не были сохранены.'
        })
      }
		}
	}
}
</script>

<style lang="sass" scoped>
.sidebar
	background-color: transparent
	position: relative
	width: 250px
	.backbutton
		visibility: hidden
	.sidebar__title
		position: relative
		display: flex
		justify-content: space-between
		font-weight: bold
		font-size: 24px
		line-height: 24px
		color: #3B3F47
		margin-bottom: 30px
		.edit-icon
			background: url("~@/assets/images/svg/edit-icon-white.svg") center no-repeat #1AC386
			width: 30px
			height: 30px
			border-radius: 50%
	.sidebar__info
		display: flex
		flex-direction: column
		align-items: center
		margin-bottom: 30px
		.info__userpic
			margin-bottom: 5px
			width: 60px
		.info__username
			text-align: center
			font-weight: 500
			font-size: 14px
			line-height: 16px
			margin-bottom: 5px
		.info__progress
			display: flex
			align-items: center
			justify-content: center
			.progress
				font-weight: 500
				font-size: 12px
				line-height: 14px
				margin-left: 5px
				color: #D88A15
				background-color: transparent
	.sidebar__menu
		width: 100%
		padding-left: 0
		margin-bottom: 0
		.menu-item
			position: relative
			transition: 0.1s linear
			background: #ffffff
			// padding: 0 20px
			height: 55px
			display: flex
			width: 100%
			justify-content: space-between
			align-items: center
			box-shadow: 0px 0px 17px -6px lightgrey
			border-radius: 12px
			background: url("~@/assets/images/svg/arrow-right.svg") no-repeat #ffffff
			background-position: calc(100% - 15px), calc(100% - 21px)
			&:hover
				background: url("~@/assets/images/svg/arrow-right-white.svg") no-repeat #1AC386
				background-position: calc(100% - 15px), calc(100% - 21px)
				cursor: pointer
				.text
					color: #fff

				&:after
					background: url("~@/assets/images/svg/checkmark-white.svg") no-repeat !important
			&.passed::after
				content: ''
				width: 15px
				height: 15px
				flex-shrink: 0
				background:
				margin-right: 30px
				background: url("~@/assets/images/svg/checkmark-green.svg") no-repeat

			&.required::before
				color: #ffffff
			&.missed
				border: 1px solid #E93030 // todo $red
			&:not(:last-child)
				margin-bottom: 10px
			&.required
				&:before
					content: 'обязательное поле*'
					position: absolute
					bottom: 5px
					left: 15px
					font-size: 8px
					line-height: 8px
					color: #CBCBCB //todo avriable $secondary-light-gray
			a
				display: flex
				justify-content: space-between
				align-items: center
				width: 100%
				height: 100%
				// padding: 0 20px
			.text
				width: 85%
				font-weight: 600
				font-size: 14px
				line-height: 16px
				color: #3B3F47
				padding-left: 15px
			&.active
				background: url("~@/assets/images/svg/arrow-right-white.svg") no-repeat #1AC386
				background-position: calc(100% - 15px), calc(100% - 21px)
				box-shadow: none
				color: #ffffff
				.text
					color: #fff
				&.required::before
					color: #ffffff
				&.passed::after
					content: ''
					width: 15px
					height: 15px
					flex-shrink: 0
					background:
					margin-right: 30px
					background: url("~@/assets/images/svg/checkmark-white.svg") no-repeat
// 1080
@media screen and (max-width: 1440px)
	.sidebar
		margin-right: 20px

// 996
@media screen and (max-width: 1180px)
	.sidebar
		margin-right: 60px
		display: none

// 900
@media screen and (max-width: 1024px)
	.sidebar
		margin-right: 40px

// 768
@media screen and (max-width: 912px)
	.sidebar
		margin-right: 20px

// 540
@media screen and (max-width: 800px)
	.sidebar
		display: none
		margin-top: 50px
		width: 100%
		.backbutton
			visibility: visible
</style>
