<template>
	<div class="front-room">
		<ul>
			<li
				class="front-room__content"
				v-for="(room, index) in rooms"
				:key="room.id"
			>
				<div class="front-room__container">
					<h3 class="front-room__title">
						<tk-input
							class="front-room__input-title"
							v-model="room.title"
							placeholder="Название комнаты"
              :error="validatorPresence(room.title, room._wasTitleInteracted)"
              @focusout="room._wasTitleInteracted = true"
						/>
					</h3>

					<tk-textarea
						class="textarea-component front-room__input-description"
						placeholder="Описание комнаты

Пример: Комната в идеальном состоянии, есть всё для комфортного проживания. Сделан свежий косметический ремонт. В комнате есть общий балкон с красивым видом на город."
						v-model="room.description"
            :error="validatorPresence(room.description, room._wasDescriptionInteracted)"
            @focusout="room._wasDescriptionInteracted = true"
					/>

					<div class="front-room__sleeping-place sleeping-place">
						<h4 class="sleeping-place__title">
							Добавьте спальные места в комнате(большая комната, гостиная, спальня)
						</h4>

						<ul class="sleeping-place__content">
							<li
								class="sleeping-place__item"
								v-for="(item, idx) in room.beds"
								:key="item"
							>
                <span @click="deleteSleepPlace(index, idx)" class="sleeping-place__del-sleep"/>
								{{ sleepPlaceObj[item.bed_type_id]?.title }}
							</li>
						</ul>

						<tk-select
							:teleported="false"
							@change="chooseSleepPlace(index, $event)"
							class="select"
							placeholder="Выберите спальные места"
						>
							<tk-option
								v-for="item in sleepPlaceList"
								:key="item.id"
								:value="item.id"
								:label="item.title"
							/>
						</tk-select>
            <div class="front-room__delete" style="color: red;" @click="deleteRoom(index)">
              <a>Удалить комнату</a>
              <tk-svg type="delete" />
            </div>
					</div>
				</div>

				<div class="front-room__uploader">
					<Uploader
            v-if="typePlace === 2"
						title="Фото комнаты"
						:default-images="room.images"
						@update="(evt) => updateImagesRoom(evt, index)"
					/>
					<UploaderBeds
						v-if="typePlace === 3"
						title="Фото спальных мест"
						is-sleep-place
						:beds="room.beds"
						:sleeping-place="sleepPlaceObj"
						:default-images="room.beds.map((itm) => itm.images).flat()"
						@update="(evt) => updateImagesSleepPlace(evt, index)"
            ref="bedsUploader"
					/>
				</div>
			</li>
		</ul>
    <tk-button
			@click="moreRoom"
			kind="opacity-border"
			class="front-room__more-room"
		>
			+ Добавить комнату
		</tk-button>
	</div>
</template>

<script>
import { arrToObj } from '@/utils/normalizeData'
import Uploader from './uploader.vue'
import UploaderBeds from "@/components/views/createPlace/uploaderBeds";

export default {
	components: { Uploader, UploaderBeds },
	props: {
		content: {
			type: Array,
			default: () => []
		},
		typePlace: {
			type: Number,
			default: null
		},
    propagateValidation: {
      type: Boolean,
      default: false
    }
	},
	watch: {
		content: function () {
			this.rooms = [...this.content]
		},
    'content.title'(newValue) {
      this.vmValues.title = this.validatePresence(newValue)
    },
    propagateValidation(newValue, oldValue) {
      if (newValue && (newValue !== oldValue)) {
        this.rooms.forEach((room) => {
          room._wasTitleInteracted = true
          room._wasDescriptionInteracted = true
        })
      }
    }
	},
	data() {
		return {
			sleepPlaceList: [],
			sleepPlaceObj: {},
			rooms: []
		}
	},
	async created() {
		const response = await this.$api.lists.getSleepingPlace()

		if (response?.status) {
			this.sleepPlaceList = [...response.response]
			this.sleepPlaceObj = arrToObj(this.sleepPlaceList, 'id')
		}
	},
	methods: {
    /**
     * Поле должно быть заполнено и не пробелами
     *
     * @param title
     * @param showError
     * @param errorMsg
     * @returns {boolean}
     */
    validatePresence(title, showError = false, errorMsg = '') {
      let isValid = true

      if (!title?.trim()) {
        isValid = false
        if(showError) {
          this.$store.commit('showNotification', {
            type: 'error',
            text: errorMsg
          })
        }
      }

      return isValid
    },
    /**
     * Передаем ошибку в tk компонент с пояснением только если
     * проверка провалена и ранее поле было не активно
     *
     * @param title
     * @param show
     * @returns {{}|{text: string, status: string}}
     */
    validatorPresence(title, show) {
      if (this.validatePresence(title) || !show) {
        return {}
      } else {
        return { status: 'error', text: 'Заполните поле' }
      }
    },
    deleteSleepPlace(roomIdx, bedIdx) {
      this.rooms[roomIdx].beds?.splice(bedIdx, 1)
      this.$refs.bedsUploader[roomIdx].images.splice(bedIdx, 1)
      this.$refs.bedsUploader[roomIdx].files.splice(bedIdx, 1)
    },
		chooseSleepPlace(roomIndex, evt) {
			this.rooms[roomIndex]?.beds.push({
				bed_type_id: evt,
				price: 0,
				images: []
			})
			this.sleep = null
		},
		updateImagesRoom(images, roomIndex) {
			if (this.rooms[roomIndex]) {
				this.rooms[roomIndex].images = images.map((itm, index) => {
          if (itm.id) {
            return {
              id: itm.id,
              is_main: index < 1 ? 1 : 0,
              image: itm.hash ?? itm.image
            }
          } else {
            return {
              is_main: index < 1 ? 1 : 0,
              image: itm.hash ?? itm.image
            }
          }
				})

				this.$emit('update', [...this.rooms])
			}
		},
		updateImagesSleepPlace(evt, roomIdx) {
      const { images, deletedIndex } = evt

      if (deletedIndex !== undefined && deletedIndex > -1) {
        const bed = this.rooms[roomIdx].beds[deletedIndex]
        this.rooms[roomIdx].beds.splice(deletedIndex, 1)
        this.rooms[roomIdx].beds.push(bed)
      }

			if (images?.length && this.rooms[roomIdx]) {
        // Грязь
        this.rooms[roomIdx].beds.forEach((bed) => {
          bed.images = []
        })
				images.forEach((itm, idx) => {
					this.rooms[roomIdx].beds[idx].images.push(itm)
				})
			}

			this.$emit('update', [...this.rooms])
		},
		moreRoom() {
			this.$emit('update', [
				...this.rooms,
				{ id: Date.now(), title: '', description: '', price: 0, area: 0, beds: [], images: [] }
			])
		},
    deleteRoom(index) {
      this.rooms.splice(index, 1)
      this.$emit('update', [
        ...this.rooms
      ])
    },
	}
}
</script>

<style lang="sass">
.front-room
  &__input-title
    margin-bottom: 25px
  &__input-description
    margin-bottom: 25px
    margin-top: 25px
  &__delete
    display: inline-flex
    align-items: center
    gap: 10px
    font-size: 14px
    line-height: 16px
    margin-top: 15px
    padding-left: 10px
    cursor: pointer
  &__description
    height: 130px
    padding: 19px 15px
    resize: none
  &__container
    width: 100%
    max-width: 420px
  &__content
    display: flex
    flex-wrap: wrap
    gap: 20px
    justify-content: space-between
    max-width: 750px
    margin-bottom: 30px
  &__uploader
    margin-top: 20px

    hr
      display: block
      margin: 15px 0

  &__more-room
    width: 240px

  .sleeping-place
    &__content
      gap: 5px
      display: flex
      flex-wrap: wrap
      margin-bottom: 5px

    &__item
      position: relative
      padding: 5px 10px
      font-weight: 500
      font-size: 12px
      line-height: 16px
      color: #3B3F47
      border: 1px solid rgba(0, 0, 0, 0.05)
      border-radius: 4px

    .select
      max-width: 310px
      .el-input
        &__wrapper
          height: 40px

    &__add
      width: 240px
      margin-top: 15px

    &__del-sleep
      position: absolute
      top: -10px
      right: -10px
      width: 17px
      height: 17px
      border: none
      background-color: transparent
      background-size: 17px 17px
      background: url("~@/assets/images/svg/close-gray.svg") center no-repeat
      cursor: pointer

    @media screen and (max-width: 800px)
      margin-bottom: 20px
  @media screen and (max-width: 800px)
    &__container
      max-width: none
    &__content
      justify-content: center

</style>
