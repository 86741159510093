<template>
	<form
		v-if="result && result.groups"
		action="#"
		class="amentities"
		id="amentities"
	>
		<fieldset
			v-for="(item, index) in result.groups"
			:key="index"
			class="amentities-main"
		>
			<legend>{{ item.title_group }}</legend>
			<ul class="amentities-main__list">
				<li
					v-for="itm in item.options"
          v-click-outside="() => itm.active = true"
					:key="itm.id"
					class="amentities-main__item"
				>
					<label for="wifi" class="label">
						<div class="amentities-main__text-wrapper">
							<div class="text">{{ itm.title }}</div>
						</div>
						<div class="switcher">
							<input
								class="tgl tgl-light"
								v-model="itm.value"
								:id="`create-place-id-${itm.id}`"
								type="checkbox"
							/>
							<label class="tgl-btn" :for="`create-place-id-${itm.id}`" />
						</div>
						<Transition name="slide-fade">
							<div v-if="itm.value" class="amintities-price">
								<div class="amintities-price__toggle switcher">
									<input
										class="tgl tgl-light"
										v-model="itm.isFree"
										:id="`create-place-id-${itm.id}-price`"
										type="checkbox"
										@change="
											(evt) => (evt.target.value ? (itm.price = 0) : null)
										"
									/>

									<label
										:for="`create-place-id-${itm.id}-price`"
										class="tgl-btn"
									/>

									<span>Бесплатно</span>
								</div>

								<tk-simple-input-number
									v-model.number="itm.price"
									:disabled="itm.isFree"
									class="input amintities-price__input-price"
								/>
							</div>
						</Transition>
					</label>
				</li>
			</ul>
		</fieldset>
	</form>
</template>

<script>
import TkSimpleInputNumber from "@/components/global/tkSimpleInputNumber";
export default {
  components: { TkSimpleInputNumber },
  props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			result: {}
		}
	},
	async created() {
		this.$bus.on('next-step', this.submit)

		const response = await this.$api.place.getAmentities({
			place_id: this.place
		})

		if (response?.status) {
			this.result = { ...response.response }
			this.result.groups[0].options = response.response.groups[0].options.slice(
				0,
				30
			)
		}
	},
  unmounted() {
    this.$bus.off('next-step', this.submit)
  },
	methods: {
		async submit(callback) {
			const response = await this.$api.place.setAmentities({
				place_id: this.place,
				value: { options: this.result.groups.map((itm) => itm.options).flat() }
			})

			// if success
      if (response?.status) {
        callback(response.response?.place_id)
      } else {
        callback()
      }
		}
	}
}
</script>

<style lang="sass">
.amentities
  display: inline-grid
  grid-template-columns: auto auto
  grid-template-rows: min-content min-content min-content
  gap: 35px
  & > fieldset:first-child
    grid-row: 1 / -1
  .title-wrapper
    margin-bottom: 30px
  fieldset.amentities-main
    width: 100%
    margin: 0
    legend
      margin-bottom: 10px
      font-size: 16px
      line-height: 18px
      padding-right: 30px
    ul
      padding: 0
      margin: 0
      border-radius: 10px
      background: #ffffff
      box-shadow: 0px 5px 9px rgba(95, 83, 80, 0.1), 0px 5px 14px rgba(0, 0, 0, 0.1)
      li
        &:not(:last-child)
          border-bottom: 0.3px solid #E2E2E2

    .text
      margin-bottom: 0
      font-size: 12px
      line-height: 12px
    .tgl-btn
      margin-bottom: 0
  &__categories
    list-style: none
    padding-left: 0
    box-shadow: 0px 0px 17px -6px lightgrey
    border-radius: 12px
    overflow: hidden
    height: fit-content
    max-width: 310px
    width: 100%
  &__item
    display: flex
    align-items: center
    padding: 15px
    max-height: 40px
    background: url('~@/assets/images/svg/arrow-right.svg') no-repeat right 10px center #fff
    transition: 0.3s
    cursor: pointer
    &:not(:last-child)
      border-bottom: 0.3px solid #E2E2E2

    &:hover,&.active
      color: $green
      background: url('~@/assets/images/svg/arrow-right-green.svg') no-repeat right 10px center #fff
  &__text
    max-width: 185px

@media screen and (max-width: 1440px)
  .amentities
    margin-bottom: 60px
    flex-flow: unset
    max-height: unset
@media screen and (max-width: 600px)
  .amentities
    grid-template-columns: 1fr
    gap: 20px
</style>

<style lang="sass">
.slide-fade-enter-active
  transition: all 0.3s ease-out

.slide-fade-leave-active
  transition: all 0.3s ease-out

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateY(-30px)
  opacity: 0


.amentities-main
  &__item
    .label
      flex-wrap: wrap

.amintities-price
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 20px
  margin-bottom: 10px
  flex-basis: 100%
  gap: 10px
  &__toggle
    display: flex
    align-items: center

    .tgl-btn
      margin-right: 10px

    span
      font-weight: 400
      font-size: 14px
      line-height: 14px
      color: #3B3F47

  &__input-price
    width: 49%
    height: 35px
</style>
