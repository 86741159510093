<template>
	<form v-if="values && type === 1" action="#" class="cost" id="cost">
		<fieldset class="cost__price">
			<legend class="cost__hdng">Цена за проживание/сут.</legend>
			<tk-simple-input-number
				id="cost-price__living"
				class="input"
				v-model="values.price"
				placeholder="Сумма"
			/>
		</fieldset>
		<fieldset class="cost__additional">
			<legend class="cost__sub-hdng">Дополнительные услуги</legend>
			<label for="cost-price__additional-guest">Дополнительный гость</label>
			<tk-simple-input-number
				id="cost-price__additional-guest"
				placeholder="Сумма"
				class="input"
				v-model="values.additional_guest_amount"
				title="Сумма доплаты за каждого доплнительного гостя"
			/>
		</fieldset>
		<p>Сумма указана в рублях &#8381;</p>
	</form>

	<p v-if="!rooms.length && (type === 2 || type === 3)">
		Необходимо создать комнаты!
	</p>

	<div v-if="rooms.length && (type === 2 || type === 3)" class="price-rooms cost">
		<ul>
			<li v-for="item in rooms" :key="item.id" class="price-rooms__item">
				<h3 class="price-rooms__room-title">{{ item.title }}</h3>

				<template v-if="type === 3">
          <fieldset class="cost__price">
              <div v-for="itm in item.beds" :key="itm.id">
                <span>{{ sleepPlace[itm.bed_type_id]?.title }}</span>
                <tk-simple-input-number class="input" v-model.number="itm.price" />
              </div>
          </fieldset>
				</template>
				<template v-else-if="type === 2">
					<fieldset class="cost__price">
						<legend class="cost__hdng">Цена за проживание/ночь</legend>
						<tk-simple-input-number
							id="cost-price__living"
							class="input"
							v-model="item.price"
							placeholder="Сумма"
						/>
					</fieldset>
					<ul>
						<li v-for="(itm, idx) in item.beds" :key="idx">
							{{ sleepPlace[itm.bed_type_id]?.title }}
						</li>
					</ul>
				</template>
			</li>
		</ul>

		<hr v-if="type === 2" />

		<fieldset v-if="type === 2" class="cost__additional">
			<legend class="cost__sub-hdng">Дополнительные услуги</legend>
			<label for="cost-price__additional-guest">Дополнительный гость</label>
			<tk-simple-input-number
				id="cost-price__additional-guest"
				placeholder="Сумма"
				class="input"
				v-model="values.additional_guest_amount"
				title="Сумма доплаты за каждого доплнительного гостя"
			/>
		</fieldset>
	</div>
</template>

<script>
import { arrToObj } from '@/utils/normalizeData'

export default {
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			sleepPlace: {},
			rooms: [],
			type: null,
			values: null
		}
	},
	async created() {
		this.$bus.on('next-step', this.submit)

		const response = await this.$api.place.getPrice({
			place_id: this.place
		})

		if (response?.status) {
			this.values = response.response
		}

		const rooms = await this.$api.place.getRooms({ place_id: this.place })

		if (rooms?.status) {
			this.rooms = rooms.response.rooms
		}

		const sleepPlaces = await this.$api.lists.getSleepingPlace()

		if (sleepPlaces?.status) {
			this.sleepPlace = arrToObj([...sleepPlaces.response], 'id')
		}

		// types
		const typesResponse = await this.$api.place.getTypes({
			place_id: this.place
		})

		if (typesResponse?.status) {
			this.type = typesResponse.response.room_types.find((itm) => itm.value)?.id
		}
	},
	unmounted() {
		this.$bus.off('next-step', this.submit)
	},
	methods: {
		async submit(callback) {
			let payload = { ...this.values }

			Object.entries(payload).forEach(([key, value]) => {
				if (+value < 1) {
					payload = { ...payload, [key]: 0 }
				}
			})

			const response = await this.$api.place.setPrice({
				place_id: this.place,
				value: {
					...payload
				}
			})

			if (this.rooms.length) {
				await this.$api.place.setRooms({
					place_id: this.place,
					value: {
						rooms: [
							...this.rooms.map((itm) => ({
								...itm,
								beds: itm.beds.map((bed) => ({
									...bed,
									images: bed.images.map((img) => ({ image: img.image?.hash }))
								})),
								images: itm.images.map((item) => ({ image: item.image?.hash }))
							}))
						]
					}
				})
			}

			if (response?.status) {
				callback(response.response?.place_id)
			} else {
				callback()
			}
		}
	}
}
</script>

<style lang="sass">
.price-rooms
  max-width: 420px

  &__room-title
    margin: 0
    margin-bottom: 20px
    font-style: normal
    font-weight: 700
    font-size: 30px
    line-height: 32px
    letter-spacing: -0.01em
    color: #3B3F47

  &__item
    margin-bottom: 30px

    &:last-child
      margin-bottom: 0

    ul
      display: flex
      flex-wrap: wrap
      gap: 5px

      li
        padding: 5px 10px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        color: #3B3F47
        border: 1px solid rgba(0, 0, 0, 0.05)
        border-radius: 4px

  label
    display: block
    margin-bottom: 20px

    &:last-child
      margin-bottom: 0

    span
      display: block
      margin-bottom: 10px
      font-weight: 600
      font-size: 14px
      line-height: 16px
      color: #3B3F47

  hr
    margin: 40px 0
    border: 1px solid rgba(0, 0, 0, 0.1)

  .cost__price
    margin-bottom: 20px !important

    > div:not(:last-child)
      margin-bottom: 20px

    input
      margin-top: 5px

    input:focus, input:hover, input:active
      border-color: #1AC386

  .cost__additional
    label
      display: block
      margin-top: 10px
</style>
