<template>
	<form action="#" class="placetype" id="placetype">
		<fieldset class="placetype-type">
			<legend>Выбрать тип жилья</legend>
			<tk-select
				class="select"
				v-model="form.type"
				:teleported="false"
				placeholder="Выберите 1 из вариантов"
			>
				<tk-option
					v-for="item in result.types"
					:key="item.id"
					:label="item.title"
					:value="item.id"
				/>
			</tk-select>
		</fieldset>

		<fieldset v-show="form.type" class="placetype-part">
			<legend>Что будет в распоряжении гостей?</legend>
			<ul class="placetype-part__list">
				<li
					v-for="item in result.room_types"
					:key="item.id"
					class="placetype-part__item"
				>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							:value="item.id"
							:id="`placetype-room-type-${item.id}`"
							:name="`placetype-room-type`"
							type="radio"
							v-model="form.room_type"
							/>
						<label class="tgl-btn" :for="`placetype-room-type-${item.id}`" />
					</div>
					<div class="placetype-part__text-wrapper">
						<div class="text">{{ item.title }}</div>
						<!-- <div class="description">
							Всё жилье целиком в распоряжении гостей: обычно это спальня,
							ванная и кухня.
						</div> -->
					</div>
				</li>
			</ul>
		</fieldset>
		<fieldset v-show="form.room_type" class="placetype-use" id="placetype-use">
			<legend>Какими помещениями сможет пользоваться гость?</legend>
			<ul class="placetype-use__list">
				<li
					v-for="room in rooms.options"
					:key="room.id"
					class="placetype-use__item"
				>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							:value="room.id"
							:id="`placetype-rooms-${form.room_type}-${room.id}`"
							:name="`placetype-rooms-${form.room_type}`"
							type="checkbox"
							v-model="form.room_type_options"
						/>
						<label
							class="tgl-btn"
							:for="`placetype-rooms-${form.room_type}-${room.id}`"
						/>
					</div>
					<div class="text">{{ room.title }}</div>
				</li>
			</ul>
		</fieldset>
	</form>
</template>

<script>
export default {
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
  inject: ["metricsService"],
	data() {
		return {
			result: [],
			form: {
				type: null,
				room_type: null,
				room_type_options: []
			}
		}
	},
	async created() {
		this.$bus.on('next-step', this.submit)

		const response = await this.$api.place.getTypes({ place_id: this.place })

		if (response?.status) {
			this.result = response.response

			this.form.type = this.result.types.find((itm) => itm.value)?.id
			this.form.room_type = this.result.room_types.find((itm) => itm.value)?.id
			this.form.room_type_options = this.result.room_type_options
				.map((itm) => itm.options)
				.flat()
				.filter((itm) => itm.value)
				.map((itm) => itm.id)
		}
	},
	unmounted() {
		this.$bus.off('next-step', this.submit)
	},
	methods: {
		async submit(callback) {
			const response = await this.$api.place.setTypes({ place_id: this.place, value: { ...this.form } })

			// // if success
			if (response?.status) {
        if (!this.place) {
          this.metricsService.sendEvent("add_home")
        }
				callback(response.response?.place_id)
			} else {
				callback()
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Тип жилья и расположение гостей обязательны для заполения'
        })
			}
		}
	},
	computed: {
		rooms() {
			return this.result.room_type_options && this.form.room_type
				? this.result.room_type_options.find(
						(itm) => itm.room_type_id === this.form.room_type
					)
				: { options: [] }
		}
	}
}
</script>

<style lang="sass">
.placetype
	fieldset legend
		display: 
		margin-bottom: 20px
	.select
		.el-input__inner
			padding: 15px !important
</style>
