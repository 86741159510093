<template>
	<form v-if="values" action="#" class="guests" id="guests">
		<fieldset class="guests-quantity quantity">
			<legend style="display:block;margin-bottom:20px">
				У каждого гостя должно быть свое комфортное спальное место.
			</legend>
			<ul class="quantity__list">
				<li class="quantity__item">
					<div class="text-wrapper">
						<p class="text">Взрослые</p>
						<p class="text_small">Возраст от 13</p>
					</div>

					<tk-input-number v-model="values.guests" :min="1" :max="10" />
				</li>
				<li class="quantity__item">
					<div class="text-wrapper">
						<p class="text">Дети</p>
						<p class="text_small">Возраст 0-12</p>
					</div>
					<tk-input-number v-model="values.children" :min="0" :max="10" />
				</li>
			</ul>
		</fieldset>
		<fieldset
			v-for="item in values.groups"
			:key="item.title_group"
			class="amenities-quantity quantity"
		>
			<legend>{{ item.title_group }}</legend>
			<ul class="quantity__list">
				<li
					v-for="itm in item.options"
					:key="itm.id"
					class="quantity__item quantity__item--children"
				>
					<div class="text-wrapper">
						<p class="text">{{ itm.title }}</p>
						<!-- <p class="text_small">Количество спальных мест</p> -->
					</div>

					<tk-input-number v-model="itm.value" :min="0" :max="20" />
				</li>
			</ul>
		</fieldset>
	</form>
</template>

<script>
export default {
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			values: null
		}
	},
  watch: {
    'values': {
      handler(newValue) {
        if (newValue.guests < 1) {
          this.values.guests = 1
          this.$store.commit('showNotification', {
            type: 'error',
            text: 'Вы должны принимать хотя бы одного взрослого гостя'
          })
        }
        if (newValue.groups[0].options[0].value + newValue.groups[0].options[1].value < 1) {
          this.values.groups[0].options[0].value = 1
          this.$store.commit('showNotification', {
            type: 'error',
            text: 'Для гостей должно быть доступно хотя бы одно спальное место'
          })
        }
      },
      deep: true
    }
  },
	async created() {
		this.$bus.on('next-step', this.submit)

    const response = await this.$api.place.getGuests({
			place_id: this.place
		})

    if (response?.status) {
      this.values = response.response
    }
	},
  unmounted() {
    this.$bus.off('next-step', this.submit)
  },
	methods: {
		async submit(callback) {
			const response = await this.$api.place.setGuests({
				place_id: this.place,
				value: {
					...this.values,
					options: this.values.groups.map((itm) => itm.options).flat()
				}
			})

      if (response?.status) {
        callback(response.response?.place_id)
      } else {
        callback()
      }
		}
	}
}
</script>

<style lang="scss" scoped>
.el-input-number {
	max-width: 95px;
}
</style>
