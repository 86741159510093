<template>
	<form v-if="values" action="#" class="rules" id="rules">
		<ul v-for="(item, index) in values.groups" :key="index" class="rules__list">
			<template v-for="itm in item.options" :key="itm.id">
				<li v-if="itm.type === 'checkbox' && (itm.children[0]?.type === 'checkbox' || itm.children.length === 0)" class="rules__item">
					<label class="label">
						<div class="text">{{ itm.title }}</div>
						<div class="switcher">
							<input
								class="tgl tgl-light"
								v-model="itm.value"
								:id="`create-place-rules-${itm.id}`"
								type="checkbox"
							/>
							<label class="tgl-btn" :for="`create-place-rules-${itm.id}`" />
						</div>
					</label>
					<transition name="slide-fade">
						<div
							v-if="itm.children?.length && itm.value"
							class="rules__item-drop"
						>
							<label
								v-for="child in itm.children"
								:key="child.id"
								class="circle-switcher"
							>
								<input
									v-model="child.value"
									:id="`create-place-sub-rules-${child.id}`"
									type="checkbox"
									hidden
								/>
								<label
									class="circle-switcher__toggle"
									:for="`create-place-sub-rules-${child.id}`"
								>
                  <tk-svg type="check"/>
                </label>
								<span class="circle-switcher__text">
									{{ child.title }}
								</span>
							</label>
						</div>
					</transition>
				</li>
        <li v-else-if="itm.children[0]?.type === 'radio'" class="rules__item">
          <label class="label">
            <div class="text">{{ itm.title }}</div>
            <div class="switcher">
              <input
                class="tgl tgl-light"
                v-model="itm.value"
                :id="`create-place-rules-${itm.id}`"
                type="checkbox"
              />
              <label class="tgl-btn" :for="`create-place-rules-${itm.id}`" />
            </div>
          </label>
          <transition name="slide-fade">
            <div
              v-if="itm.children?.length && itm.value"
              class="rules__item-drop"
            >
              <label
                v-for="child in itm.children"
                :key="child.id"
                class="circle-switcher"
              >
                <input
                  v-model="itm.radioValue"
                  :id="`create-place-sub-rules-${child.id}`"
                  :value="child.id"
                  type="radio"
                  hidden
                />
                <label
                  class="circle-switcher__toggle"
                  :for="`create-place-sub-rules-${child.id}`"
                >
                  <tk-svg type="check"/>
                </label>
                <span class="circle-switcher__text">
									{{ child.title }}
								</span>
              </label>
            </div>
          </transition>
        </li>
				<li
					v-else
					class="rules__item rules__item_dropdown"
					:class="{ active: dropdownActive }"
				>
					<label class="label" @click="dropdownActive = !dropdownActive">
						{{ itm.title }}
					</label>

					<div class="dropdown" v-show="dropdownActive">
						<div class="title-wrapper">
							<p class="title" style="margin-top: 0">Дополнительные правила</p>
							<!-- <button
								class="close-btn"
								type="button"
								@click="dropdownActive = !dropdownActive"
							/> -->
						</div>
						<textarea
							name="other_reason_textarea"
							class="dropdown__textarea"
							v-model="itm.value"
							placeholder="Текст"
						/>
						<!-- <button class="done-btn" @click="dropdownActive = !dropdownActive">
							Готово
						</button> -->
					</div>
				</li>
			</template>
		</ul>
	</form>
</template>

<script>
export default {
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			values: null,
			dropdownActive: false
		}
	},
	async created() {
		this.$bus.on('next-step', this.submit)

		const response = await this.$api.place.getRules({
			place_id: this.place
		})

		if (response?.status) {
      response.response.groups.forEach((group) => {
        group.options.forEach((option) => {
          if (option.children[0]?.type === "radio") {
            option.children.forEach((children) => {
              if (children.value === true) {
                option.radioValue = children.id
              }
            })
          }
        })
      })
			this.values = response.response
		}
	},
	unmounted() {
		this.$bus.off('next-step', this.submit)
	},
	methods: {
		async submit(callback) {
      this.values.groups.forEach((group) => {
        group.options.forEach((option) => {
          if (option.children[0]?.type === "radio" && option.radioValue) {
            option.children.forEach((children) => {
              if (children.id === option.radioValue) {
                children.value = true
              } else {
                children.value = false
              }
            })
            // option.radioValue = undefined
          }
        })
      })

      const flatter = (groups, result) => {
        groups.forEach((group) => {
          result.push({
            id: group.id,
            type: group.type,
            value: group.value
          })
          if (group.children) flatter(group.children, result)
        })
      }

      const groups = this.values.groups.map((itm) => itm.options).flat()
      const values = []
      flatter(groups, values)

      const response = await this.$api.place.setRules({
        place_id: this.place,
        value: {
          options: values
        }
      })

			if (response?.status) {
				callback(response.response?.place_id)
			} else {
				callback()
			}
		}
	}
}
</script>

<style lang="sass">
.circle-switcher
  display: flex
  align-items: center
  padding: 7px 15px
  cursor: pointer

  &__toggle
    display: inline-flex
    justify-content: center
    align-items: center
    margin-right: 10px
    width: 16px
    height: 16px
    border: 1px solid #1AC386
    border-radius: 16px

    .svg-icon
      width: 10px
      height: 6px

  &__text
    font-weight: 400
    font-size: 12px
    line-height: 12px
    color: #8991A2

  input:checked ~ label
    background-color: #1AC386
</style>

<style lang="sass">
.rules
  &__item-drop
    padding-bottom: 19px
</style>
