<template>
  <div class="thumbnails-wrapper" :style="{'height': containerHeight}">
    <h3>{{ title }}</h3>
    <ul class="list" ref="list">
      <template v-if="isSleepPlace">
        <li v-for="itm of beds" :key="itm.id" class="item">
          <span class="item__text">
            {{ sleepingPlace[itm.bed_type_id]?.title }}
          </span>
        </li>
      </template>
      <template v-else>
        <li v-for="itm of count + 1" :key="itm" class="item">
          {{ itm === 0 ? 'Основное фото' : '' }}
        </li>
      </template>
      <li v-if="loading" class="item thumbnails-wrapper__load-text">
        Подождите. Идёт загрузка фото...
      </li>
    </ul>

    <tk-upload
        action="#"
        ref="upload"
        class="thumbnails-wrapper__uploader"
        list-type="picture-card"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="handleBeforeRemove"
        accept=".jpeg, .png, .jpg"
        :disabled="loading"
        :file-list="files"
        :limit="isSleepPlace ? beds.length : 9"
        multiple
        :auto-upload="false"
      >
      <tk-svg type="btn-plus" />
    </tk-upload>
  </div>
</template>

<script>
import { ConcurrencyManager } from 'axios-concurrency'
import { httpInstance } from '@/plugins/api'
import { debounce } from '@/utils/async'
import sizes from "@/constants/sizes";

export default {
  props: {
    isSleepPlace: {
      type: Boolean,
      default: false
    },
    sleepingPlace: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: 'Фото жилья'
    },
    defaultImages: {
      type: Array,
      default: () => []
    },
    beds: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    files: {
      handler() {
        this.count = this.files.length
      },
      deep: true
    }
  },
  data() {
    return {
      containerHeight: 'auto',
      count: 0,
      files: [],
      images: [],
      loading: false,
      fileName: '',
      deletedIndex: -1
    }
  },
  computed: {
    maxSize() {
      return sizes.megabyte * 10
    }
  },
  beforeMount() {
    if (this.defaultImages?.length) {
      this.images = this.defaultImages
      this.files = this.convertToImagesArrayForElementPlus([...this.images])
    }
  },
  mounted() {
    // обход ошибки с высотой элемента
    // в противном случае аплоадер может прятаться под элементы следующей формы
    this.computeContainerHeight();
  },
  updated() {
    // обход ошибки с высотой элемента
    // в противном случае аплоадер может прятаться под элементы следующей формы
    this.computeContainerHeight();
  },
  methods: {
    computeContainerHeight() {
      const uploaderHeight = this.$refs.upload.$el.children[0].clientHeight + 25;
      const listHeight = this.$refs.list.clientHeight + 25;
      this.containerHeight = (Math.max(uploaderHeight, listHeight)).toString() + 'px';
    },
    handleChange(_, fileList) {
      if (this.isSleepPlace && !this.beds?.length) {
        this.files = []

        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Сначала надо выбрать спальные места'
        })

        return
      }

      this.files = fileList.filter((itm) => {
        if (itm.size > this.maxSize) {
          if (itm.name !== this.fileName) {
            this.$store.commit('showNotification', {
              type: 'error',
              text: 'Размер фото должен не превышать 10 мб'
            })

            this.fileName = itm.name
          }

          return false
        } else {
          return true
        }
      })

      this.debounceSubmit(this)
    },
    handleBeforeRemove(file, fileList) {
      this.deletedIndex =
        Math.max(fileList.findIndex((f) => f.url === file.url),
          fileList.findIndex((f) => f.uid === file.uid))
    },
    handleRemove(file, fileList) {
      this.files = fileList

      this.images = this.images
        .map((itm) => {
          if (fileList.find((item) => item.url === itm.origin)) {
            return itm
          }
        })
        .filter((itm) => itm)

      this.$emit('update', {
        images: this.images,
        deletedIndex: this.deletedIndex})
      this.deletedIndex = -1
    },
    debounceSubmit: debounce(function (self) {
      self.submit()
    }),
    async submit() {
      const payload = this.files.filter((itm) => itm.raw) // check files is was upload

      if (!payload.length) {
        return
      }

      this.loading = true
      const manager = ConcurrencyManager(httpInstance, 1)
      const response = await Promise.all(
        payload.map((itm) => this.$api.upload.save({ data: [itm] }))
      )
      manager.detach()
      this.loading = false

      if (response.find((itm) => !itm.status)) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Ошибки сохранения'
        })
      } else {
        const images = response
          .filter((itm) => itm.status)
          .map((itm) => itm.response)
          .flat()

        if (
          this.images.find((item) =>
            images.map((itm) => itm.hash).includes(item.hash)
          )
        ) {
          this.$store.commit('showNotification', {
            type: 'error',
            text: 'Вы не можете загружать одинаковые фотографии'
          })

          this.files = this.convertToImagesArrayForElementPlus(this.images)
          this.$emit('update', {
            images: this.images
          })

          return
        }

        this.images = [...this.images, ...images]
        this.files = this.convertToImagesArrayForElementPlus(this.images)
        this.$emit('update', {
          images: this.images
        })

        setTimeout(() => {
          this.$store.commit('showNotification', {
            type: 'success',
            text: 'Успешно сохранено'
          })
        }, 5000)
      }
    },
    convertToImagesArrayForElementPlus(payload) {
      return payload.map((itm) => ({
        name: itm.origin,
        url: itm.origin
      }))
    }
  }
}
</script>

<style lang="sass" >
.thumbnails-wrapper
  position: relative
  max-width: 310px

  h3
    margin: 0
    margin-bottom: 15px
    font-weight: 600
    font-size: 16px
    line-height: 18px
    color: #3B3F47

  &__load-text
    display: flex
    justify-content: center

  .buttons-wrapper
    width: 100%
    height: 40px
    display: flex
    align-items: center
    justify-content: space-between

  &__submit-btn,
  &__del-btn
    width: 150px
    height: 40px
    color: white
    border-radius: 11px
    background-color: $green
    box-shadow: 0px 10px 18px rgba(95, 83, 80, 0.1), 0px 10px 28px rgba(0, 0, 0, 0.1)

  @media screen and (max-width: 912px)
    width: 100%

  @media screen and (max-width: 800px)
    margin: 0 auto
    width: inherit
    max-width: inherit

    h3
      text-align: center

  @media screen and (max-width: 500px)
    margin-bottom: 20px

  div
    margin-bottom: 50px
    bottom: -20px

  .list
    list-style: none
    margin: 0
    margin-bottom: 20px
    margin-top: -1px
    padding-left: 0
    display: grid
    grid-template-columns: repeat(2, 135px)
    grid-column-gap: 8px
    grid-row-gap: 13px
    // position: absolute
    z-index: -1

    @media screen and (max-width: 800px)
      left: 0
      right: 0
      justify-content: center

    .item
      width: 135px
      height: 75px
      // padding-top: 10px
      border-radius: 12px
      background: white
      border: 1px solid #E2E2E2
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      font-weight: 600
      font-size: 14px
      line-height: 14px
      color: #CBCBCB

      @media screen and (max-width: 500px)
        width: 100%

  &__uploader
    position: absolute
    top: 33px
    left: 0

    .el-upload
      &:focus
        color: $green
        border-color: $green

      &--picture-card
        margin: 0
        width: 135px
        height: 75px
        border-radius: 12px
        background-color: transparent

        .svg-icon
          margin-top: 10px

          path
            fill: $green

          circle
            stroke: $green

        &:hover
          color: $green
          border-color: $green

    .el-upload-list
      display: grid
      grid-template-columns: repeat(2, 135px)
      grid-column-gap: 8px
      grid-row-gap: 13px

      &__item
        width: 135px
        min-width: 135px
        height: 75px
        min-height: 75px
        margin: 0

        &-delete
          margin-left: 0 !important

        &-status-label
          display: none !important

      &-actions:hover span
        display: none

      &__item-preview
        display: none !important

      &__item-delete
        margin-left: 0
</style>
