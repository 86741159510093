<template>
	<form action="#" class="conditions" id="conditions">
		<div class="conditions-wrapper-left">
			<fieldset class="conditions__unwanted-dates">
				<legend>Нежелаемые даты*</legend>
				<p class="description">
					Отметьте в календаре дни, когда вы бы не хотели принимать гостей
				</p>
				<template v-for="(item, index) in values.unwanted_dates" :key="index">
					<div
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							margin-bottom: 20px;
							gap: 10px;
						"
					>
						<tk-datepicker
							class="unwanted-dates-picker"
							type="daterange"
							v-model="item.values"
							format="DD.MM.YYYY"
							:id="`datepicker-${index}`"
							@change="
								(evt) => {
									;(item.unwanted_from = evt[0]), (item.unwanted_to = evt[1])
								}
							"
							value-format="YYYY-MM-DD"
							:teleported="false"
							popper-class="date-time-picker"
							start-placeholder="Выбрать"
							end-placeholder="Выбрать"
						/>
						<a class="conditions__icon" @click="deleteUnwantedDate(index)"> X </a>
					</div>
				</template>

				<tk-button
					@click="addAnotherDate"
					kind="opacity-border"
					:disabled="values.unwanted_dates > 9"
					class="conditions__add-date"
					style="width: 215px"
				>
					+ Добавить другие даты
				</tk-button>
			</fieldset>

			<fieldset class="conditions__check-in">
				<legend>Заселение</legend>
<!--				<legend>Заселение* (Въезд - выезд)</legend>-->
        <div class="conditions__check-in--item">
          <p>Въезд</p>
          <el-time-picker
              v-model="values.in_time"
              :disabled-seconds="() => {}"
              placeholder="Въезд"
              format="HH:mm"
              class="conditions__timepicker select"
          />
        </div>
        <div class="conditions__check-in--item">
          <p>Выезд</p>
          <el-time-picker
              v-model="values.out_time"
              :disabled-seconds="() => {}"
              placeholder="Выезд"
              format="HH:mm"
              class="conditions__timepicker select"
          />
        </div>
			</fieldset>
			<fieldset class="conditions__instant-booking">
				<legend>Мгновенное бронирование</legend>
				<ul class="conditions__list">
					<li class="conditions__item">
						<label for="instant-booking" class="label instant-booking-label">
							<div class="text">
								Подтверждать запрос на бронирование автоматически
							</div>
							<div class="switcher">
								<input
									class="tgl tgl-light"
									id="instant-booking"
									:true-value="true"
									:false-value="false"
									v-model="values.auto_booking_confirmation"
									type="checkbox"
								/>
								<label class="tgl-btn" for="instant-booking"></label>
							</div>
						</label>
					</li>
				</ul>
			</fieldset>
			<fieldset class="conditions__identification">
				<legend>Подтверждение личности гостя</legend>
				<ul class="conditions__list">
					<li class="conditions__item">
						<label
							for="identification-only"
							class="label instant-booking-label"
						>
							<div class="text">
								Принимать гостей только с подтвержденными документами
							</div>
							<div class="switcher">
								<input
									class="tgl tgl-light"
									id="identification-only"
									:true-value="true"
									:false-value="false"
									v-model="values.guests_with_verified_documents_only"
									type="checkbox"
								/>
								<label class="tgl-btn" for="identification-only"></label>
							</div>
						</label>
					</li>
				</ul>
			</fieldset>
		</div>
		<div class="conditions-wrapper-right">
			<fieldset class="conditions__max-days-quantity">
				<legend>Минимальное количество дней бронирования*</legend>
				<p class="description">
					Укажите на какое минимально количество дней можно забронировать ваше
					жилье
				</p>
				<!-- class="select" -->
				<!-- v-model="gender" -->
				<!-- :teleported="false" -->
				<!-- placeholder="-" -->
				<tk-select
					class="select"
					v-model="values.min_days_booking"
					clearable
					:teleported="false"
					placeholder="Выбрать"
				>
					<tk-option
						class="select__option"
						v-for="item in minDaysOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</tk-option>
				</tk-select>
			</fieldset>
			<fieldset class="conditions__cancellation">
				<legend>Правила отмены бронирования*</legend>
				<label for="cancellation_days" class="description">
					Укажите количество дней ДО наступления срока бронирования, когда
					действует период бесплатной отмены бронирования гостем
				</label>
				<input
					class="input conditions__cancellation_days"
					id="cancellation_days"
					type="number"
					placeholder="Дней"
					v-model.number="values.free_cancellation_days"
				/>
				<label for="cancellation_penalty" class="description">
					Укажите % неустойки, который взимается с гостя после окончания срока
					бесплатной отмены бронирования
				</label>
				<input
					id="cancellation_penalty"
					class="input conditions__cancellation_penalty"
					type="number"
					v-model.number="values.percentage_forfeit"
					placeholder="Укажите %"
				/>
			</fieldset>
		</div>
	</form>
</template>

<script>
import { ElTimePicker } from "element-plus";

export default {
	props: {
		place: {
			type: [String, Number],
			default: null
		}
	},
  components: { ElTimePicker },
	data() {
		return {
			values: {
				unwanted_dates: [{ unwanted_from: null, unwanted_to: null }]
			}
		}
	},
	async created() {
		this.$bus.on('next-step', this.submit)

		const response = await this.$api.place.getBooking({
			place_id: this.place
		})

		if (response?.status) {
			this.values = {...response.response}
      this.values.in_time = this.timeFromResponseFormat(this.values.in_time)
      this.values.out_time = this.timeFromResponseFormat(this.values.out_time)
			if (!this.values.unwanted_dates.length) {
				this.values.unwanted_dates.push({
					unwanted_from: null,
					unwanted_to: null,
					values: []
				})
			} else {
				this.values.unwanted_dates = this.values.unwanted_dates.map((itm) => {
					return {
						...itm,
						values: [itm.unwanted_from, itm.unwanted_to]
					}
				})
			}
		}
	},
	unmounted() {
		this.$bus.off('next-step', this.submit)
	},
	computed: {
		minDaysOptions() {
			return [
				{
					value: 1,
					label: '1 день'
				},
				{
					value: 3,
					label: '3 дня'
				},
				{
					value: 14,
					label: '14 дней'
				},
				{
					value: 30,
					label: '1 месяц'
				}
			]
		}
	},
	methods: {
    timeFromResponseFormat(str) {
      const hours = parseInt(str.substr(0, 2))
      const minutes = parseInt(str.substr(3, 2))
      const time = new Date()
      time.setHours(hours)
      time.setMinutes(minutes)
      return time
    },
    timeToResponseFormat(time) {
      return ('0' + time.getHours()).slice(-2) +
        ':' +
        ('0' + time.getMinutes()).slice(-2)
    },
		deleteUnwantedDate(index) {
			this.values.unwanted_dates.splice(index, 1)
		},
		updateTime(value, field) {
			if (!/^([01]\d|2[0-3]):?([0-5]\d)$/.test(value)) {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Неверный формат!'
				})

				this.values[field]
			} else {
				this.values[field] = value
			}
		},
		addAnotherDate() {
			this.values.unwanted_dates.push({
				unwanted_from: null,
				unwanted_to: null,
				values: []
			})
		},
		async submit(callback) {
			const response = await this.$api.place.setBooking({
				place_id: this.place,
				value: {
					...this.values,
					unwanted_dates: this.values.unwanted_dates.filter(
						(itm) => itm.unwanted_from
					),
					in_time:
            this.values?.in_time ?
              this.timeToResponseFormat(this.values.in_time)
              : '00:00',
					out_time:
              this.values?.out_time ?
              this.timeToResponseFormat(this.values.out_time)
              : '23:59',
				}
			})
			if (response?.status) {
				callback(response.response?.place_id)
			} else {
				callback()
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.conditions
  display: grid
  grid-template-columns: repeat(2, 310px)
  grid-column-gap: 130px
  .conditions-wrapper-left
    fieldset
      position: relative
      margin-bottom: 20px
      legend
        margin-bottom: 5px
        font-weight: 600
        font-size: 14px
        line-height: 16px
        color: #3B3F47

  label
    background-color: white
    border-radius: 10px
    &.instant-booking-label
      height: 40px
      padding-top: 0
      padding-bottom: 0
    .text
      margin-bottom: 0 !important
  .select
    width: 100%
  .unwanted-dates-picker
    width: 100%
    border-radius: 12px
  &__check-in
    .check-in__input
      position: relative
      width: 200px
      z-index: 1
      &:not(:last-child)
        margin-bottom: 20px
        margin-right: 10px
      &:before
        z-index: 3
        content: ''
        position: absolute
        left: 15px
        top: 14px
        font-size: 12px
        line-height: 12px

    .check-in-time
      &:before
        content: 'Заезд'
    .check-out-time
      &:before
        content: 'Выезд'

    .select-trigger
      height: 40px
  &__list
    background: #ffffff
    padding-left: 0
    border-radius: 10px
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    height: 40px
    font-size: 12px
    line-height: 14px
    .text
      padding-right: 10px
      margin-bottom: 0
  &__max-days-action
    .conditions__item
      &:first-child
        border-bottom: 0.3px solid #E2E2E2
  &__max-days-quantity
    .select
      height: 40px
  &__cancellation
    input
      width: 100%
      height: 40px
      border: 1px solid #E2E2E2
      border-radius: 12px
      caret-color: #1AC386
      padding: 14px
      &:not(:last-child)
        margin-bottom: 30px
  &__icon
    cursor: pointer

@media screen and (max-width: 1180px)
  .conditions
    grid-column-gap: 79px

@media screen and (max-width: 1024px)
  .conditions
    grid-template-columns: repeat(2, 300px)
    grid-column-gap: 15px

@media screen and (max-width: 912px)
  .conditions
    grid-template-columns: 1fr
    justify-content: center
</style>

<style lang="sass">
.unwanted-dates-picker
  width: 100% !important
  height: 40px !important
  border-radius: 12px !important
  font-size: 12px
  line-height: 12px
  caret-color: #1AC386
  cursor: pointer
  background-color: white
  //margin-bottom: 20px

.conditions
  .conditions__check-in--item
    display: flex
    align-items: center
    margin-bottom: 10px
    gap: 10px
    & > div
      margin: 0
      height: auto
    p
      font-size: 16px
      margin: 0
  .description
    display: block
    margin-top: 5px
    margin-bottom: 8px
    font-size: 14px
    line-height: 16px
  .conditions__timepicker
    background: #FFFFFF
    border: 1px solid #E2E2E2
    border-radius: 12px
    margin-bottom: 10px
  .select
    .el-input__wrapper,
    .el-input__inner
      height: 40px
      box-shadow: none !important
      border: none
      border-radius: 12px
  .check-in
    &__input
      max-width: 200px
      input
        height: 40px
</style>
